const colors = {
  transparent: 'transparent', // 0
  black: '#000', // 1
  white: '#fff', // 2
  headingColor: '#302b4e',
  textColor: '#43414e', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#ebebeb', // 7
  primary: '#6b7d98', // 8
  primaryHover: '#8897ac', // 9
  secondary: '#f4511e', // 10
  secondaryHover: '#db481b', // 11
  yellow: '#f4511e', // 12
  yellowHover: '#db481b', // 13
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
  secondaryBoxShadow: '0px 4px 20px -6px rgba(107, 125, 152, 0.5)',
};

export default colors;
