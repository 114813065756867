import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
// import Icon from "react-icons-kit";
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
// import GlideCarousel from "common/src/components/GlideCarousel";
// import GlideSlide from "common/src/components/GlideCarousel/glideSlide";

import PricingTable from // PricingButtonWrapper, // ListItem, // PricingList, // PricingButton, // PricingPrice, // PricingHead,
'./pricing.style';

// import { checkmark } from "react-icons-kit/icomoon/checkmark";

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
  buttonWrapper,
  button,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MONTHLY_PRICING_TABLE {
          name
          description
          price
          priceLabel
          buttonLabel
          url
          listItems {
            content
          }
        }
        YEARLY_PRICING_TABLE {
          name
          description
          price
          priceLabel
          buttonLabel
          url
          listItems {
            content
          }
        }
      }
    }
  `);

  const [state, setState] = useState({
    data: Data.saasModernJson.MONTHLY_PRICING_TABLE,
    active: true,
    memberCount: '20',
    totalCostPerMember: 0.9,
    totalCostPerYear: 216.0,
  });

  // const data = state.data;
  // const activeStatus = state.active;

  // const pricingCarouselOptions = {
  //     type: "slider",
  //     perView: 3,
  //     gap: 30,
  //     bound: true,
  //     breakpoints: {
  //         1199: {
  //             perView: 2,
  //             peek: {
  //                 before: 100,
  //                 after: 100,
  //             },
  //         },
  //         990: {
  //             perView: 1,
  //             peek: {
  //                 before: 160,
  //                 after: 160,
  //             },
  //         },
  //         767: {
  //             perView: 1,
  //             peek: {
  //                 before: 80,
  //                 after: 80,
  //             },
  //         },
  //         575: {
  //             perView: 1,
  //             gap: 15,
  //             peek: {
  //                 before: 20,
  //                 after: 20,
  //             },
  //         },
  //     },
  // };

  function calculateTotalCost(event) {
    event.preventDefault();

    let count = Math.min(Math.max(parseInt(event.target.value, 10), 0), 1000);
    if (isNaN(count)) {
      setState({
        memberCount: '',
        totalCostPerMember: 0,
        totalCostPerYear: 0,
      });
      return;
    }

    const b1 = Math.min(count, 10);
    const b2 = Math.min(count - b1, 10);
    const b3 = Math.min(count - b2 - b1, 10);
    const b4 = Math.min(count - b3 - b2 - b1, 10);
    const b5 = count - b4 - b3 - b2 - b1;

    // const cost = b1 * 1.8 + b2 * 1.7 + b3 * 1.6 + b4 * 1.5 + b5 * 1.4;

    // APPLY DISCOUNT.
    const cost = b1 * 1.0 + b2 * 0.8 + b3 * 0.6 + b4 * 0.5 + b5 * 0.4;

    setState({
      memberCount: count,
      totalCostPerMember: count === 0 ? 0 : cost / count,
      totalCostPerYear: cost * 12,
    });
  }

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text
            {...secText}
            content="Unser Angebot"
            style={{ textTransform: 'uppercase' }}
          />
          <Heading {...secHeading} content="Ein abgestuftes Preismodell" />
          {/*<PricingButtonWrapper>*/}
          {/*  <Button*/}
          {/*    title="Monatlich"*/}
          {/*    className={activeStatus ? 'active-item' : ''}*/}
          {/*    onClick={() =>*/}
          {/*      setState({*/}
          {/*        data: Data.saasModernJson.MONTHLY_PRICING_TABLE,*/}
          {/*        active: true,*/}
          {/*      })*/}
          {/*    }*/}
          {/*  />*/}
          {/*  <Button*/}
          {/*      title="Jährlich"*/}
          {/*      className={activeStatus === false ? 'active-item' : ''}*/}
          {/*      onClick={() =>*/}
          {/*          setState({*/}
          {/*            data: Data.saasModernJson.YEARLY_PRICING_TABLE,*/}
          {/*            active: false,*/}
          {/*          })*/}
          {/*      }*/}
          {/*  />*/}
          {/*</PricingButtonWrapper>*/}
        </Box>
        <Box {...row} style={{ margin: '0 5%' }}>
          <PricingTable
            className="pricing_table"
            style={{ width: '100%', border: '8px solid #6b7d98' }}
          >
            {/*<Heading {...secHeading}*/}
            {/*         content="Wie viele Mitglieder hat Ihr Verein?"*/}
            {/*         style={{marginBottom: "1em", color: "#f4511e"}} />*/}

            <div className="price-calculator">
              Bei einem Verein mit
              <input
                type="number"
                min="1"
                max="1000"
                placeholder="0"
                value={state.memberCount}
                onChange={calculateTotalCost}
              />
              Schützen belaufen sich die Kosten auf
              <strong> CHF&nbsp;{state.totalCostPerMember.toFixed(2)} </strong>
              pro Schütze
              <wbr />/<wbr />
              Monat. Der Gesamtbetrag pro Jahr für den Verein ist
              <strong> CHF&nbsp;{state.totalCostPerYear.toFixed(2)} </strong>.
            </div>

            <Box {...buttonWrapper}>
              <a
                href="https://app.marksmen.ch/"
                target="_blank"
                rel="noreferrer"
              >
                <Button {...button} title="Jetzt kostenlos Testen" />
              </a>
            </Box>

            {/*<table>*/}
            {/*    <tbody>*/}
            {/*    <tr>*/}
            {/*        <td>1-10</td>*/}
            {/*        <td>1.80</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>11-20</td>*/}
            {/*        <td>1.70</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>21-30</td>*/}
            {/*        <td>1.60</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>31-40</td>*/}
            {/*        <td>1.50</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>41+</td>*/}
            {/*        <td>1.40</td>*/}
            {/*    </tr>*/}
            {/*    </tbody>*/}
            {/*</table>*/}
          </PricingTable>
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['100px', '100px', '100px', '100px', '100px'],
    pb: ['60px', '80px', '80px', '100px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#6b7d98',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    bg: '#fff',
    color: '#6b7d98',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '18px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default PricingSection;
