import React from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import Container from 'common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';

import FaqWrapper from './faq.style';

const FaqSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  title,
  description,
  buttonWrapper,
  button,
}) => {
  // const Data = useStaticQuery(graphql`
  //   query {
  //     saasModernJson {
  //       FAQ_DATA {
  //         title
  //         description
  //         expand
  //       }
  //     }
  //   }
  // `);

  return (
    <Box {...sectionWrapper} id="faq_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text
            {...secText}
            content="Informationen"
            style={{ textTransform: 'uppercase' }}
          />
          <Heading {...secHeading} content="Häufig gestellte Fragen" />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <FaqWrapper>
              <Accordion>
                <AccordionItem className="accordion_item">
                  <>
                    <AccordionTitle className="accordion_title">
                      <>
                        <Heading
                          {...title}
                          content="Funktioniert die Webseite/App auch auf Smart Phones?"
                        />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={minus} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={plus} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </>
                    </AccordionTitle>
                    <AccordionBody className="accordion_body">
                      <>
                        <p>
                          Ja, auf <strong>marksmen.ch</strong> kann sowohl von
                          Desktop PCs (Windows, OSX, Linux) als auch von Smart
                          Phones (Android/iOS) aus mit vollem Funktionsumfang
                          zugegriffen werden.
                        </p>
                      </>
                    </AccordionBody>
                  </>
                </AccordionItem>

                <AccordionItem className="accordion_item">
                  <>
                    <AccordionTitle className="accordion_title">
                      <>
                        <Heading
                          {...title}
                          content="Wie genau wird der Preis berechnet?"
                        />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={minus} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={plus} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </>
                    </AccordionTitle>
                    <AccordionBody className="accordion_body">
                      <>
                        <p>
                          Der Preis basiert auf der Anzahl Schützen im Verein
                          und wird abgestuft berechnet: je mehr Schützen, desto
                          tiefer die Kosten pro Vereinsmitglied. Die Kosten
                          stellen sich wie folgt zusammen, jeweils pro Schütze
                          pro Monat: für die ersten 10 Schützen, CHF 1.00/Monat;
                          11-20 Schützen, CHF 0.80/Monat; 21-30 Schützen, CHF
                          0.60/Monat; 31-40 Schützen, CHF 0.50/Monat; danach nur
                          noch 0.40/Monat pro Schütze.
                        </p>
                        <p>
                          <strong>Beispiel:</strong> Ein Schützenverein möchte
                          seine 25 Mitglieder registrieren. Dabei werden die
                          ersten 10 Schützen mit CHF 1.— berechnet, die zweiten
                          10 Schützen mit CHF 0.80 und die restlichen 5 Schützen
                          noch mit CHF 0.60. Die Kosten für den Verein belaufen
                          sich auf CHF 21.— pro Monat bzw. CHF 252.— pro Jahr.
                          Dies entspricht einem Durchschnittspreis pro Schütze
                          von rund CHF 10.—/Jahr.
                        </p>
                      </>
                    </AccordionBody>
                  </>
                </AccordionItem>

                <AccordionItem className="accordion_item">
                  <>
                    <AccordionTitle className="accordion_title">
                      <>
                        <Heading
                          {...title}
                          content="Welche Sprachen werden unterstützt?"
                        />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={minus} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={plus} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </>
                    </AccordionTitle>
                    <AccordionBody className="accordion_body">
                      <>
                        <p>
                          Die App ist derzeit vollumfänglich auf{' '}
                          <strong>Deutsch</strong> und <strong>Englisch</strong>{' '}
                          verfügbar. Unterstützung für Französich und
                          Italienisch ist in Planung, diese Sprachen werden in
                          naher Zukunft ebenfalls verfügbar sein.
                        </p>
                      </>
                    </AccordionBody>
                  </>
                </AccordionItem>

                <AccordionItem className="accordion_item">
                  <>
                    <AccordionTitle className="accordion_title">
                      <>
                        <Heading
                          {...title}
                          content="Wie kontaktiere ich den Support?"
                        />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={minus} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={plus} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </>
                    </AccordionTitle>
                    <AccordionBody className="accordion_body">
                      <>
                        <p>
                          Gerne beantworten wir Ihre Fragen zu unserem Angebot
                          und leisten technische Unterstützung. Sie erreichen
                          uns per E-Mail unter der Adresse
                          <a href="mailto:support@marksmen.ch">
                            {' '}
                            <b>support@marksmen.ch</b>
                          </a>
                          .
                        </p>
                      </>
                    </AccordionBody>
                  </>
                </AccordionItem>

                {/*<>*/}
                {/*  {Data.saasModernJson.FAQ_DATA.map((accordionItem, index) => (*/}
                {/*    <AccordionItem*/}
                {/*      className="accordion_item"*/}
                {/*      key={`accordion-${index}`}*/}
                {/*      expanded={accordionItem.expand}*/}
                {/*    >*/}
                {/*      <>*/}
                {/*        <AccordionTitle className="accordion_title">*/}
                {/*          <>*/}
                {/*            <Heading {...title} content={accordionItem.title} />*/}
                {/*            <IconWrapper>*/}
                {/*              <OpenIcon>*/}
                {/*                <Icon icon={minus} size={18} />*/}
                {/*              </OpenIcon>*/}
                {/*              <CloseIcon>*/}
                {/*                <Icon icon={plus} size={18} />*/}
                {/*              </CloseIcon>*/}
                {/*            </IconWrapper>*/}
                {/*          </>*/}
                {/*        </AccordionTitle>*/}
                {/*        <AccordionBody className="accordion_body">*/}
                {/*          <Text*/}
                {/*            {...description}*/}
                {/*            content={accordionItem.description}*/}
                {/*          />*/}
                {/*        </AccordionBody>*/}
                {/*      </>*/}
                {/*    </AccordionItem>*/}
                {/*  ))}*/}
                {/*</>*/}
              </Accordion>
            </FaqWrapper>
            <Box {...buttonWrapper}>
              <a href="#pricing_section">
                <Button {...button} title="Zu den Angeboten" />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

FaqSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '100px', '100px'],
    pb: ['60px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#6b7d98',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
  title: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: 0,
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: 0,
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default FaqSection;
